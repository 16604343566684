import { graphql, PageProps } from "gatsby"
import tw from "twin.macro"
import Seo from "../components/SEO"
import react from "react"

const SourcesPage: React.FC<{data: any}> = ({data}) => {
    const sources = data.allSources.nodes[0]
    
    return (
      <div css={[tw` h-[80vh] lg:h-[70vh] py-48 lg:w-2/3 px-5 lg:px-48 flex flex-col justify-center mx-auto text-gray-700`]}>
      <Seo title="Sources"/>
          <h5 css={[tw`mb-10  `]}>Sources: </h5>
          <p>{sources.careerDataSource}</p>
          <p>{sources.educationDataSource}</p>
          <p>{sources.lifestyleDataSource}</p>
      </div>
    )
  }
  
  export default SourcesPage

  export const query = graphql`
  {
    allSources {
    nodes {
      id
      lifestyleDataSource
      educationDataSource
      careerDataSource
    }
  }
  }`